import { Product, scaleImage, mapListVideo } from '@news-mono/web-common'
import React, { Fragment, useEffect, useState } from 'react'
import { IconVideo2, Skeleton } from '../..'
import { Sharing } from '../../buttons/Sharing/Sharing'
import { StyledIconVideo2 } from '../../cards/Kicker/Kicker'
import {
    StyledKicker,
    StyledKickerText,
} from '../../cards/Kicker/Kicker.styled'
import { PlayOverlay } from '../../content/Video/components/PlayOverlay/PlayOverlay'
import { TitleOverlay } from '../../content/Video/components/TitleOverlay/TitleOverlay'
import { UnmuteButton } from '../../content/Video/components/UnmuteButton/UnmuteButton'
import { RenderPlayerParams } from '../../content/Video/player-render-params'
import { getHumanDuration } from '../../content/Video/utils/get-human-duration'
import { getPosterImage } from '../../content/Video/utils/poster'
import { getImageWidth } from '../../content/Video/utils/product-info'
import {
    StyledVideo,
    StyledWrapper,
    videoClassname,
} from '../../content/Video/Video.styled'
import { TextLink } from '../../typography/TextLink/TextLink'
import { Carousel } from '../Carousel/Carousel'
import {
    StyledVideoPlayerDescription,
    StyledVideoPlayerHeadline,
    StyledVideoPlayerTextContainer,
    StyledVideoPlayerWrapper,
} from '../VideoPlayer/VideoPlayer.styled'
import { useViewport } from '../../__helpers/use-viewport'
import {
    StyledSharingWrap,
    StyledVideoPlayerByline,
} from './VideoHubVideoSkin.styled'
import {
    SevenVideoDescription,
    SevenVideoProfileLink,
} from './VideoDescription'
export const videoWrapperClassName = 'video-wrapper'

const getVideoPlayerDescriptionWithLinks = (description: string) => {
    const doc = new DOMParser().parseFromString(description, 'text/html')
    const mappedTextWithLinkComponents = [...doc.body.childNodes].map(
        (el, i) => {
            if (el.nodeName === 'A') {
                const linkNode = el as HTMLAnchorElement
                return (
                    <TextLink
                        key={i}
                        href={linkNode.href}
                        openInNewWindow={true}
                    >
                        {linkNode.textContent}
                    </TextLink>
                )
            } else {
                return el.textContent
            }
        },
    )

    return <React.Fragment>{mappedTextWithLinkComponents}</React.Fragment>
}

export const VideoHubVideoSkin: React.FC<RenderPlayerParams> = ({
    theme,
    wrapperElProps,
    videoElement,
    playlistProps,
    playerState,
    playerOptions,
    playerActions,
    hideSticky,
    setHideSticky,
    publicUrl,
    onPlayNextVideo,
    isLoading,
    withH1,
}) => {
    const [isShowMore, setIsShowMore] = useState(true)
    const { isMobile } = useViewport()
    const isMobileBauhaus = playerState.showInternalPlayButton && isMobile

    //prevent displaying full description on video change
    useEffect(() => {
        setIsShowMore(true)
    }, [playerState.currentVideo])

    // if devices is mobile(autoplay disabled) AND has an interal play button AND has no play button text
    // AND is on sevennews AND is not a bauhaus themed section
    const isMobileSeven =
        !playerState.canAutoplay &&
        playerState.showInternalPlayButton &&
        !playerOptions.playButtonText &&
        theme.kind === Product.SevenNews

    // variable used to display video title on load on mobile seven (including bauhaus)
    const showVideoTitle = isMobileBauhaus || isMobileSeven

    const renderUnmuteButton = playerState.isMuted &&
        !playerState.showTitleOverlay &&
        (theme.kind === Product.SevenNews
            ? true
            : !playerState.showInternalPlayButton) && (
            <UnmuteButton
                positionState={{
                    alignToTop: playerOptions.compactMode,
                    isLowered:
                        theme.kind === Product.SevenNews
                            ? false
                            : (!playerState.showTopBar &&
                                  playerState.adPlayed) ||
                              (!playerState.showTopBar &&
                                  playerState.adPlaying),
                }}
                action={playerActions.handleMuteToggle}
            />
        )

    const renderPlayOverlay = !playerState.showInternalPlayButton ? (
        false
    ) : (
        <PlayOverlay
            play={playerActions.playVideo}
            isCentered={true}
            isSmall={playerOptions.smallPlayButton}
        />
    )

    const renderTitleOverlay = (showVideoTitle ||
        playerState.showTitleOverlay) && (
        <TitleOverlay
            showVideoTitle={showVideoTitle}
            play={playerActions.playVideo}
            cancelAutoplay={playerActions.stopAutoplay}
            videoDuration={
                playerState.currentVideoMetadata
                    ? getHumanDuration(
                          playerState.currentVideoMetadata.duration,
                      )
                    : undefined
            }
            compactMode={playerOptions.compactMode || false}
            videoDetails={playerState.currentVideo}
            autoplayRemainingTime={
                playerState.currentState.type === 'autoplay-countdown'
                    ? playerState.currentState.remainingTime
                    : undefined
            }
        />
    )

    const { innerRef, ...StyledVideoWrapperProps } = wrapperElProps
    const posterImageCrop = getPosterImage(playerState.currentVideo)
    let posterImageScaled: string | undefined

    if (posterImageCrop) {
        posterImageScaled = scaleImage(
            posterImageCrop.reference,
            getImageWidth(theme.kind),
            false,
        )
    }

    if (playlistProps && playerState.currentVideoMetadata) {
        const currentVideoItem = mapListVideo(
            playerState.currentVideoMetadata,
            { publicUrl: publicUrl ?? '' },
        )

        return (
            <Fragment>
                {posterImageScaled && (
                    <style
                        dangerouslySetInnerHTML={{
                            __html: `
                                @media print {
                                    .${videoClassname}::before {
                                        content: url("${posterImageScaled}");
                                        max-width: 600px;
                                    }
                                }`,
                        }}
                    />
                )}
                <StyledWrapper
                    className={videoWrapperClassName}
                    compactMode={playerOptions.compactMode}
                    hide={hideSticky && playerOptions.compactMode}
                    isShowMore={isShowMore}
                    isVideoHub={true}
                >
                    <StyledVideoPlayerWrapper>
                        {isLoading ? (
                            <Skeleton />
                        ) : (
                            <>
                                <StyledVideo
                                    {...StyledVideoWrapperProps}
                                    ref={innerRef}
                                    posterImage={posterImageScaled}
                                >
                                    {renderPlayOverlay}
                                    {renderTitleOverlay}
                                    {videoElement}
                                    {renderUnmuteButton}
                                </StyledVideo>
                                <StyledVideoPlayerTextContainer>
                                    {theme.kind === 'perthnow' && (
                                        <StyledKicker
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                            size={'large'}
                                        >
                                            {
                                                currentVideoItem.primaryTopic
                                                    .title
                                            }
                                        </StyledKicker>
                                    )}
                                    {theme.kind === 'thewest' && (
                                        <StyledKicker
                                            style={{
                                                textTransform: 'uppercase',
                                            }}
                                            size={'large'}
                                        >
                                            {
                                                currentVideoItem.primaryTopic
                                                    .title
                                            }
                                            <StyledKickerText>
                                                <IconVideo2
                                                    width={14}
                                                    height={9}
                                                    horizontalSpacing={6}
                                                    className={StyledIconVideo2}
                                                />
                                            </StyledKickerText>
                                        </StyledKicker>
                                    )}
                                    {theme.kind === 'sevennews' && (
                                        <StyledKicker>
                                            {
                                                currentVideoItem.primaryTopic
                                                    .title
                                            }
                                        </StyledKicker>
                                    )}
                                    <StyledVideoPlayerHeadline
                                        as={withH1 ? 'h1' : 'h2'}
                                    >
                                        {currentVideoItem.headline}
                                    </StyledVideoPlayerHeadline>
                                    {theme.kind === 'sevennews' ? (
                                        <SevenVideoDescription
                                            description={
                                                currentVideoItem.teaser
                                            }
                                            showMore={isShowMore}
                                            showMoreHandler={() =>
                                                setIsShowMore((prev) => !prev)
                                            }
                                        />
                                    ) : (
                                        <StyledVideoPlayerDescription>
                                            {getVideoPlayerDescriptionWithLinks(
                                                currentVideoItem.teaser,
                                            )}
                                        </StyledVideoPlayerDescription>
                                    )}
                                    <StyledVideoPlayerByline>
                                        {theme.kind === 'sevennews' && (
                                            <SevenVideoProfileLink
                                                profiles={
                                                    currentVideoItem.profiles
                                                }
                                            />
                                        )}
                                        <StyledSharingWrap className="StyledSharingWrap">
                                            <Sharing
                                                className="BauhausCardSharing"
                                                url={currentVideoItem._self}
                                                text={currentVideoItem.headline}
                                                isGhostStyle={true}
                                                onEvent={() => {}}
                                                shareOptions={[
                                                    'facebook',
                                                    'twitter',
                                                    'clipboard',
                                                ]}
                                                isBauhaus={true}
                                            />
                                        </StyledSharingWrap>
                                    </StyledVideoPlayerByline>
                                </StyledVideoPlayerTextContainer>
                            </>
                        )}
                    </StyledVideoPlayerWrapper>

                    {playlistProps &&
                        playlistProps.initialVideoMetadata &&
                        playerState.currentVideoMetadata && (
                            <Carousel
                                onEvent={() => {}}
                                itemMeta={
                                    playlistProps.videoQueue.items
                                        ? playlistProps.videoQueue.items
                                        : []
                                }
                                currentVideo={currentVideoItem}
                                currentPath={'/'}
                                onSelect={playlistProps.handlePlaylistSelect}
                                isLoading={isLoading}
                            />
                        )}
                </StyledWrapper>
            </Fragment>
        )
    } else {
        return null
    }
}
